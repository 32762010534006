import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, Route, Routes, useParams } from "react-router-dom";
import { useStateValue } from "providers";
import { fetchPlace } from "actions";
import { useGeolocation, useGetQuery } from "hooks";
import { Box, Card, GameList, Map, PostChiclet, ShowPosts, StyledTitle, Tabs, Tab, TabPanel, TextInput, Title } from "components";
import { Chat, Wall } from "./components";

const Stage = styled(Box)`
  overflow: 'visible';
  box-sizing: 'border-box';
  flex: 3;
  display: flex;
  flex-direction: column;
`

const Page = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const Place = () => {
  const { id } = useParams();
  const {
        loading,
        error,
        data: { latitude, longitude },
    } = useGeolocation()

  const { data, reFetch } = useGetQuery({url: `/locations/${id}`})
  useEffect(() => {
    reFetch(id)
  }, [id]);


  if (!data) return null;

  return (
    <Page>
      <Title level={2} color="link">
        {data?.location?.name}
      </Title> 
    <Stage>
      <Tabs>
        <Tab to={`/places/${id}`} end>Post</Tab>
        <Tab to={`/places/${id}/wall`} end>wall</Tab>
        <Tab to={`/places/${id}/activities`} end>activities</Tab>
        <Tab to={`/places/${id}/comments`} end>chat</Tab>
        <Tab to={`/places/${id}/details`} end>details</Tab>
      </Tabs>
      <Routes>
        <Route path="/" element={<><ShowPosts postable_id={data?.location?.id} postable_type="location"/><PostChiclet postable_id={data?.location?.id} postable_type="location"/></>} />
        <Route path={`/details`} element={<><Map className="user_place-show" locations={data?.location?.addresses} zoom={18} />
          </>} />
        <Route path={`/activities`} element={<GameList queue={data.location?.addresses?.map((a) => a.Queues).flat()} />} />
        <Route path="/comments" element={<Chat />} />
        <Route path="/wall" element={<Wall place={data.location} />} />
      </Routes>
    </Stage>
    </Page>
  );
};
